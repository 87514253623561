import React from "react";
import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import {
  AccordionSummary,
  Backdrop,
  Grid,
  Paper,
  Popper,
  Typography,
  TypographyProps,
} from "@mui/material";
import { SimpleCard } from "../cards";
import { Link } from "../links";
import { shouldForwardProp } from "../utils";

export const StyledAccordionSummary = styled(AccordionSummary)`
  ${({ theme }): SerializedStyles => css`
    width: fit-content;
    margin-bottom: ${theme.spacing(1)};
    padding-left: 0;
    min-height: auto;
    .MuiAccordionSummary-content {
      margin: 0;
    }
  `}
`;

export const DesktopContainer = styled("div")`
  ${({ theme }): SerializedStyles => css`
    height: 100%;
    ${theme.breakpoints.down("sm")} {
      display: none;
    }
  `}
`;

export const MobileContainer = styled("div")`
  ${({ theme }): SerializedStyles => css`
    ${theme.breakpoints.up("sm")} {
      display: none;
    }
  `}
`;

export const SummaryBlockContainer = styled(Grid)`
  ${({ theme }): SerializedStyles => css`
    padding: ${theme.spacing(2)};
    height: 100%;
  `}
`;

export const PageListBlockLinkContainer = styled("div")`
  ${({ theme }): SerializedStyles => css`
    ${theme.cssMixins.rowHCentered};
    > a {
      margin: ${theme.spacing(1, 0)};
    }
    ${theme.breakpoints.down("sm")} {
      padding: ${theme.spacing(0, 1)};
    }
  `}
`;

export const PageListBlockItem = styled("div")`
  ${({ theme }): SerializedStyles => css`
    ${theme.cssMixins.rowVCentered};
    justify-content: flex-start;
    cursor: pointer;
    padding: ${theme.spacing(1, 2)};
    border-radius: ${theme.shape.borderRadius};
    &:hover {
      background-color: ${theme.palette.site.header.blockBackground};
    }
    ${theme.breakpoints.down("sm")} {
      padding: ${theme.spacing(1)};
    }
  `}
`;

export const PageListBlockIcon = styled("img")`
  ${({ theme }): SerializedStyles => css`
    width: 40px;
    height: 40px;
    margin-right: ${theme.spacing(2)};
  `}
`;

export const PageListBlockContent = styled("div")`
  ${({ theme }): SerializedStyles => css`
    > h5 {
      margin-bottom: ${theme.spacing(0.5)};
    }
  `}
`;

export const MainInfoCTA = styled(Link)`
  ${({ theme }): SerializedStyles => css`
    margin-top: ${theme.spacing(1)};
    width: fit-content;
  `}
`;

export const StyledBackdrop = styled(Backdrop)`
  background: transparent;
`;

function MenuLabelWrapperBase(props: TypographyProps): React.ReactElement {
  return <Typography component="span" variant="navItem" {...props} />;
}

export const MenuLabelWrapper = styled(MenuLabelWrapperBase, {
  shouldForwardProp,
})<PpTransparentBg & PpOpen>`
  ${({ $open, $transparentBg, theme }): SerializedStyles => css`
    ${$open && "position: relative"};
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    height: 100%;
    transition: ${theme.transitions.create("color")};
    color: ${$transparentBg
      ? theme.palette.primary.contrastText
      : theme.palette.primary.main};
    color: ${$open && theme.palette.site.header.activeLink};
    ${theme.breakpoints.down("sm")} {
      color: ${$open
        ? theme.palette.site.header.activeLink
        : theme.palette.primary.main};
    }
    ${theme.breakpoints.up("sm")} {
      text-shadow: ${$transparentBg ? theme.shadows[4] : "none"};
      &:hover {
        color: ${theme.palette.site.header.activeLink};
      }
    }
    > svg {
      ${theme.cssMixins.twister({ angle: $open ? -180 : 0 })};
    }
  `}
`;

export const MenuLabel = styled("span")`
  height: 100%;
  display: flex;
  align-items: center;
`;

type PpProgressBar = {
  $showProgressBar: boolean;
};

export const StyledPopper = styled(Popper)`
  z-index: 6000;
`;

export const Arrow = styled("span", { shouldForwardProp })<PpProgressBar>`
  ${({ $showProgressBar, theme }): SerializedStyles => css`
    position: absolute;
    top: ${$showProgressBar ? "17px" : "13px"};
    display: block;
    font-size: 7px;
    margin-top: -1.8em;
    width: 6em;
    height: 2em;
    &::before {
      content: "";
      margin: auto;
      display: block;
      width: 0;
      height: 0;
      border-width: 0 2em 2em 2em;
      border-color: transparent transparent ${theme.palette.background.paper}
        transparent;
      border-style: solid;
    }
  `}
`;

export const ContentContainer = styled(Paper, {
  shouldForwardProp,
})<PpProgressBar>`
  ${({ $showProgressBar, theme }): SerializedStyles => css`
    ${theme.cssMixins.headerMenuWidth};
    margin-top: ${$showProgressBar ? "17px" : "13px"};
    max-height: 600px;
    overflow: auto;
    padding: ${theme.spacing(2)};
    background-color: ${theme.palette.background.paper};
    box-shadow: ${theme.shadows[1]};
    border-radius: ${theme.shape.siteBorderRadius.lg};
  `}
`;

export const MenuWrapper = styled("div")`
  height: 100%;
`;

export const StyledMenuBlockFeatured = styled(SimpleCard)`
  ${({ theme }): SerializedStyles => css`
    height: 100%;
    width: 100%;
    background-color: ${theme.palette.site.header.blockBackground};
  `}
`;
